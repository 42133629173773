function createObject() {
  let example = {
    id: "LBST_1+1_Ableitungsaufgaben_Stempeln_Kim_V1",
    workOrder: `Links sehen Sie eine Kinderaussage. Rechts finden Sie Fragen dazu. Dabei gibt es unterschiedlich viele Fragen pro Entscheidungsstufe. Sie müssen die jeweils vorangegangene Entscheidungsstufe richtig beantworten, um die Fragen der nächsten Stufe beantworten zu können.`,
    image: require("@/assets/epe/Stempeln/Kim.png"),
    elements: [
      [
        {
          question:
            "Welches Rechengesetz liegt der Ableitungsstrategie von Kim zugrunde?",
          answers: [
            {
              correct: false,
              text: "Das Assoziativgesetz",
              response: `Schauen Sie sich das Vorgehen von Kim noch einmal genauer an. Zur Erinnerung das Assoziativgesetz: (a+b)+c= a+(b+c)`,
            },
            {
              correct: false,
              text: "Das Konstanzgesetz der Differenz",
              response: `Schauen Sie sich das Vorgehen von Kim noch einmal genauer an. Zur Erinnerung das Konstanzgesetz der Differenz: (a±1)-(b±1), Subtrahend und Minuend werden dabei gleichsinnig verändert.`,
            },
            {
              correct: true,
              text: "Das Konstanzgesetz der Summe",
              response: `Sehr gut, Kim nutzt hier das Konstanzgesetz der Summe und verändert die Summanden gegensinnig: a+b = (a±1)+(b±1)`,
            },
            {
              correct: false,
              text: "Das Kommutativgesetz",
              response: `Schauen Sie sich das Vorgehen von Kim noch einmal genauer an. Zur Erinnerung das Kommutativgesetz: a+b = b+a.`,
            },
          ],
        },
      ],
      [
        {
          question:
            "Welche Eigenschaft der Aufgabe führt dazu, dass es sinnvoll ist, das Gesetz anzuwenden?",
          answers: [
            {
              correct: true,
              text:
                "Das Konstanzgesetz der Summe kann angewendet werden, damit eine Kernaufgabe erreicht wird; zum Beispiel wenn ein Summand dadurch ein glatter Zehner wird.",
              response: `Durch die gegensinnige Veränderung beider Summanden kann eine leichtere Aufgabe erzeugt werden, da z. B. durch einen glatten Zehner der Zehnerübergang umgangen wird.`,
            },
            {
              correct: false,
              text:
                "Das Konstanzgesetz der Differenz kann angewendet werden, damit der Minuend oder Subtrahend ein glatter Zehner wird.",
              response: `Das ist hier so nicht richtig. Das Konstanzgesetz der Differenz gilt für Subtraktionsaufgaben, die hier nicht gegeben sind. Es handelt sich um eine Additionsaufgabe und vielmehr werden die beiden Summanden gegensinnig verändert, um eine leichtere Aufgabe mit demselben Ergebnis zu erhalten. Bei der Subtraktion würden Minuend und Subtrahend hingegen gleichsinnig verändert.`,
            },
            {
              correct: false,
              text:
                "Das Assoziativgesetz kann durch die Zerlegung eines Summanden den Zehnerübergang erleichtern.",
              response: `Das ist hier so nicht richtig. Bei Kims Rechenvorgehen, wird kein Summand zerlegt. Vielmehr werden beide Summanden gegensinnig verändert, um eine leichtere Aufgabe mit demselben Ergebnis zu erhalten.`,
            },
            {
              correct: false,
              text:
                "Das Kommutativgesetz kann genutzt werden, da die Vertauschung der beiden Summanden zu einer einfacheren Aufgabe führt, beispielsweise weil der erste Summand nach dem Vertauschen besonders nah am Zehnerübergang ist.",
              response: `Das ist hier so nicht richtig. Hier werden die Summanden nicht vertauscht. Grundsätzlich kann diese Strategie aber hilfreich sein.`,
            },
          ],
        },
      ],
    ],
  };
  return example;
}

export default createObject();
