<template>
  <div>
    <h2 class="faledia-caption">Aufgabenbeziehungen nutzen</h2>
    <v-card outlined>
      <v-card-title>Was sollen die Schüler:innen lernen?</v-card-title>
      <v-card-text>
        Die Kinder können...
        <ul>
          <li>
            Kernaufgaben an der Einspluseinstafel erkennen.
          </li>
          <li>
            Aufgabenbeziehungen an der Einspluseinstafel erkennen.
          </li>
          <li>
            Beziehungen zwischen Aufgaben für vorteilhaftes Rechnen nutzen.
          </li>
          <li>
            auf die Aufgaben des Einspluseins automatisiert zurückgreifen.
          </li>
        </ul>
      </v-card-text>
    </v-card>
    <br />
    <p>
      Die Einspluseinstafel stellt die Aufgaben des kleinen Einspluseins mit den
      Summanden zwischen 0 und 10 auf geordnete Weise dar. Ein zentraler
      Lerngegenstand der Schuleingangsphase ist die Automatisierung dieser
      Aufgaben, da diese als Grundlage für die Einführung der halbschriftlichen
      und schriftlichen Rechenstrategien gelten. Dafür sollen Kinder diese aber
      nicht als „Einzelfakten“ isoliert voneinander auswendig lernen, sondern
      sie sich durch das Nutzen von Beziehungen zwischen den Aufgaben
      erschließen und diese so nachhaltig verfügbar haben (Selter & Zannetin,
      2018).
    </p>

    <v-card elevation="0" style="width: 90%; margin-inline: auto;">
      <v-card-title>Einspluseinstafel</v-card-title>
      <v-card-text>
        <v-row>
          <v-col align-self="center">
            <!--
        <v-col class="flex">
          <v-row><v-btn @click="changeImage(require('@/assets/epe/einspluseinstafel_alle.png'))">Alle</v-btn></v-row>
          <v-row>
            <v-btn @click="changeImage(require('@/assets/epe/einspluseinstafel_0.png'))">Summand 0</v-btn>
          </v-row>

          <v-row><v-btn @click="changeImage(require('@/assets/epe/einspluseinstafel_1.png'))">Summand 1</v-btn></v-row>
          <v-row><v-btn @click="changeImage(require('@/assets/epe/einspluseinstafel_10.png'))">Summand 10</v-btn></v-row>
          <v-row><v-btn @click="changeImage(require('@/assets/epe/einspluseinstafel_5.png'))">Summand 5</v-btn></v-row>
          <v-row
            ><v-btn @click="changeImage(require('@/assets/epe/einspluseinstafel_VA.png'))"
              >Verdopplungsaufgaben <br />(Aufgaben mit gleichen
              Summanden)</v-btn
            ></v-row
          >
          <v-row
            ><v-btn @click="changeImage(require('@/assets/epe/einspluseinstafel_AZ.png'))"
              >Aufgaben zur Zerlegung der 10 <br />(vgl.
              Teil-Ganzes-Konzept)</v-btn
            ></v-row
          >
          -->
            <v-simple-table>
              <template v-slot:default>
                <tbody>
                  <tr>
                    <td
                      class="flexEinspluseins eineZeile"
                      @click="
                        changeImage(require('@/assets/epe/1+1-Tafel_keine.png'))
                      "
                    >
                      Keine
                      <v-btn small elevation="0">&rarr;</v-btn>
                    </td>
                  </tr>
                  <tr>
                    <td
                      class="flexEinspluseins eineZeile"
                      @click="
                        changeImage(require('@/assets/epe/1+1-Tafel_A0.png'))
                      "
                    >
                      Summand 0
                      <v-btn small elevation="0">&rarr;</v-btn>
                    </td>
                  </tr>
                  <tr>
                    <td
                      class="flexEinspluseins eineZeile"
                      @click="
                        changeImage(require('@/assets/epe/1+1-Tafel_A1.png'))
                      "
                    >
                      Summand 1
                      <v-btn small elevation="0">&rarr;</v-btn>
                    </td>
                  </tr>
                  <tr>
                    <td
                      class="flexEinspluseins eineZeile"
                      @click="
                        changeImage(require('@/assets/epe/1+1-Tafel_A10.png'))
                      "
                    >
                      Summand 10
                      <v-btn small elevation="0">&rarr;</v-btn>
                    </td>
                  </tr>
                  <tr>
                    <td
                      class="flexEinspluseins eineZeile"
                      @click="
                        changeImage(require('@/assets/epe/1+1-Tafel_A5.png'))
                      "
                    >
                      Summand 5
                      <v-btn small elevation="0">&rarr;</v-btn>
                    </td>
                  </tr>
                  <tr>
                    <td
                      class="flexEinspluseins zweiZeilen"
                      @click="
                        changeImage(
                          require('@/assets/epe/1+1-Tafel_Verdopplung.png')
                        )
                      "
                    >
                      Verdopplungsaufgaben <br />(Aufgaben mit gleichen
                      Summanden)
                      <v-btn small elevation="0">&rarr;</v-btn>
                    </td>
                  </tr>
                  <tr>
                    <td
                      class="flexEinspluseins zweiZeilen"
                      @click="
                        changeImage(
                          require('@/assets/epe/1+1-Tafel_Zerlegung 10.png')
                        )
                      "
                    >
                      Aufgaben zur Zerlegung der 10 <br />(vgl.
                      Teil-Ganzes-Konzept)
                      <v-btn small elevation="0">&rarr;</v-btn>
                    </td>
                  </tr>
                  <tr>
                    <td
                      class="flexEinspluseins eineZeile"
                      @click="
                        changeImage(require('@/assets/epe/1+1-Tafel_alle.png'))
                      "
                    >
                      Alle
                      <v-btn small elevation="0">&rarr;</v-btn>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-col>
          <v-col align-self="center" style="max-width: 60%;">
            <AppExpandableImage
              contain
              :src="this.einspluseinstafelBild"
              elementId="einspluseinstafel.png"
              parentId="epe"
            >
            </AppExpandableImage>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <p style="text-align: right">
      entnommen aus
      <i
        ><a href="https://mahiko.dzlm.de/node/49"
          >https://mahiko.dzlm.de/node/49
        </a></i
      >
    </p>
    <br />

    <p>
      Um gewinnbringend mit der Einspluseinstafel umgehen zu können, ist es
      notwendig, ihre Struktur zu erfassen. Besonders einprägsame Aufgaben sind
      farblich hervorgehoben und werden weithin als Kernaufgaben bezeichnet. Die
      Zahlbeziehungen zu benachbart liegenden Termen sollten im Unterricht durch
      die Kinder in den Blick genommen werden, um sie schließlich für
      vermeintlich schwierigere Aufgaben nutzen zu können.
    </p>
    <div class="image">
      <AppExpandableImage
        contain
        :src="require('@/assets/epe/Aufgabenbeziehungen_Summand.png')"
        elementId="Aufgabenbeziehungen_Summand.png"
        parentId="epe"
      >
      </AppExpandableImage>
    </div>
    <br />

    <p>
      Durch die Kernaufgaben werden 85 der 121 Aufgaben des kleinen Einspluseins
      erfasst. Diese müssen zwar ebenso wie die restlichen 36 Aufgaben von den
      Lernenden eingeprägt werden, allerdings fällt es den Kindern häufig
      leichter sich die Kernaufgaben einzuprägen. Auch hier bestehen aufgrund
      der Struktur der Tafel Möglichkeiten, um die Automatisierung zu
      vereinfachen (Nach Götze, Selter & Zannetin, 2019, vgl. auch Padberg &
      Benz, 2021):
    </p>
    <v-simple-table>
      <template v-slot:default>
        <tbody>
          <tr>
            <td><strong>Tauschaufgabe</strong></td>
            <td>
              Nach dem Kommutativgesetz lassen sich die Summanden vertauschen.
              Die Anzahl der zu lernenden Aufgaben reduziert sich somit auf 18.
            </td>
            <td>4+5 = 5+4</td>
          </tr>
          <tr>
            <td><strong>Nachbaraufgaben</strong></td>
            <td>
              Einer der Summanden einer Kernaufgabe wird um 1 vergrößert oder
              verringert, um die gesuchte Aufgabe ableiten zu können.
            </td>
            <td>9+8, wird abgeleitet aus 10+8</td>
          </tr>
          <tr>
            <td><strong>Partneraufgaben</strong></td>
            <td>
              Beide Summanden werden gegensinnig verändert. Nach dem
              Konstanzgesetz der Addition bleibt die Summe konstant.
            </td>
            <td>7+6=8+5</td>
          </tr>
          <tr>
            <td><strong>Analogieaufgaben</strong></td>
            <td>
              Ein Summand wird um einen bestimmten Wert erhöht, wodurch sich
              auch die Summe einer Kernaufgabe um einen bestimmten Wert erhöht.
            </td>
            <td>7+5, wird zu 17+5, dadurch ist die Summe, um 10 größer.</td>
          </tr>
          <tr>
            <td><strong>Schrittweises Rechnen</strong></td>
            <td>
              Einer der beiden Summanden wird mithilfe des Assoziativgesetzes
              zerlegt und die Teilsummanden werden einzeln hinzuaddiert.
            </td>
            <td>7+5=(7+3)+2</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <br />
    <p>
      Den Rechengesetzen kommt eine besondere Bedeutung für die
      Ableitungsstrategien an der Einspluseinstafel zu. Auch wenn die
      Schüler:innen die Rechenstrategien nicht explizit versprachlichen können,
      so nutzen sie diese dennoch implizit in der Umsetzung der Strategien aus,
      wenn sie sich schwierigere Aufgaben aus Kernaufgaben erschließen. Das
      Kommutativgesetz wird in der Grundschule meist genutzt, um den größeren
      Summanden an die erste Position zu tauschen und das Rechnen somit zu
      erleichtern. Das Assoziativgesetz wird auf drei verschiedene Weisen
      gedeutet (Wittmann et al., 2021):
    </p>
    <ol>
      <li>
        Schrittweise Berechnung einer Summe (durch Zerlegung eines Summanden und
        Addierens beider Teilsummanden)
      </li>
      <li>Erhöhung einer Summe durch Erhöhung eines Summanden</li>
      <li>Als Partneraufgaben, mittels gegensinniger Veränderung</li>
    </ol>
    <p>
      Sollten Sie die Rechengesetze noch einmal wiederholen wollen, schauen Sie
      sich die im Folgenden aufklappbare Tabelle an:
    </p>
    <AppButtonCollapse
      messageHidden="Wiederholung Rechengesetze anzeigen"
      messageNotHidden="Wiederholung Rechengesetze verstecken"
    >
      <div style="background-color: white">
        <v-row>
          <v-col cols="gesetzeTabelleFormat">
            <!-- Kommutativgesetze-Tabelle -->
            <v-simple-table>
              <template v-slot:default>
                <tbody>
                  <tr style="text-align: center">
                    <td colspan="2"><strong>Kommutativgesetz</strong></td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Addition: </strong>Die Summanden einer
                      Additionsaufgabe können vertauscht werden, ohne dass sich
                      dabei das Ergebnis ändert.
                    </td>
                    <td class="no-wrap">
                      <v-row no-gutters justify="center">8 + 3 = 3 + 8</v-row>
                      <v-row no-gutters justify="center">a + b = b + a</v-row>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Multiplikation: </strong>Die Faktoren einer
                      Multiplikationsaufgabe können vertauscht werden, ohne dass
                      sich dabei das Ergebnis ändert.
                    </td>
                    <td class="no-wrap">
                      <v-row no-gutters justify="center">7 · 4 = 4 · 7</v-row>
                      <v-row no-gutters justify="center">a · b = b · a</v-row>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="2">
                      <i
                        >Das <strong>Kommutativgesetz </strong>gilt nicht für
                        die Subtraktion (8 – 4 ist ungleich 4 – 8) und auch
                        nicht für die Division (6 : 3 ist ungleich 3 : 6)</i
                      >
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-col>

          <v-col cols="gesetzeTabelleFormat">
            <!-- Assoziativgesetz-Tabelle-->
            <v-simple-table>
              <template v-slot:default>
                <tbody>
                  <tr style="text-align: center">
                    <td colspan="2"><strong>Assoziativgesetz</strong></td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Addition: </strong>Die Summanden einer
                      (mehrgliedrigen) Additionsaufgabe können beliebig
                      miteinander verbunden werden.
                    </td>
                    <td class="no-wrap">
                      <v-row no-gutters justify="center"
                        >(2 + 3) + 1 = 2 + (3 + 1)</v-row
                      >
                      <v-row no-gutters justify="center"
                        >(a + b) + c = a + (b + c)</v-row
                      >
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Multiplikation: </strong>Die Faktoren einer
                      (mehrgliedrigen) Multiplikationsaufgabe können beliebig
                      miteinander verbunden werden.
                    </td>
                    <td class="no-wrap">
                      <v-row no-gutters justify="center"
                        >(6 · 5) · 7 = 6 · (5 · 7)</v-row
                      >
                      <v-row no-gutters justify="center"
                        >(a · b) · c = a · (b · c)</v-row
                      >
                    </td>
                  </tr>
                  <tr>
                    <td colspan="2">
                      <i
                        >Das <strong>Assoziativgesetz </strong>gilt nicht für
                        die Subtraktion, denn (8 – 4) – 3 = 4 – 3 = 1 ist
                        ungleich 8 – (4 – 3) = 8 – 1 = 7 und auch nicht für die
                        Division: (36 : 6) : 3 = 6 : 3 = 2 ist ungleich 36 : (6
                        : 3) = 36 : 2 = 18.</i
                      >
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="gesetzeTabelleFormat">
            <!-- Distributivgesetz-Tabelle -->
            <v-simple-table>
              <template v-slot:default>
                <tbody>
                  <tr style="text-align: center">
                    <td colspan="2"><strong>Distributivgesetz</strong></td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Addition/ Multiplikation bzw. Division: </strong
                      >Eine Summe wird mit einem Faktor multipliziert (bzw.
                      durch einen Divisor dividiert), indem jeder Summand mit
                      dem Faktor multipliziert (bzw. durch den Divisor
                      dividiert) wird und die Resultate addiert werden.
                    </td>
                    <td class="no-wrap">
                      <v-row no-gutters justify="center"
                        >6 · 4 = 2 · 4 + 4 · 4
                      </v-row>
                      <v-row no-gutters justify="center"
                        >(a + b) · c = a · c + b · c
                      </v-row>
                      <v-row no-gutters justify="center"
                        >72 ː 8 = 56 ː 8 + 16 ː 8
                      </v-row>
                      <v-row no-gutters justify="center"
                        >(a + b) ː c = a ː c + b ː c</v-row
                      >
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong
                        >Subtraktion/ Multiplikation bzw. Division: </strong
                      >Eine Differenz wird mit einem Faktor multipliziert (bzw.
                      durch einen Divisor dividiert), indem Minuend und
                      Subtrahend mit dem Faktor multipliziert (bzw. durch den
                      Divisor dividiert) werden und die Resultate voneinander
                      subtrahiert werden.
                    </td>
                    <td class="no-wrap">
                      <v-row no-gutters justify="center"
                        >7 · 4 = 10 · 4 – 3 · 4</v-row
                      >
                      <v-row no-gutters justify="center"
                        >(a – b) · c = a · c – b · c</v-row
                      >
                      <v-row no-gutters justify="center"
                        >186 ː 6 = 198 ː 6 – 12 ː 6</v-row
                      >
                      <v-row no-gutters justify="center"
                        >(a- b) ː c = a ː c – b ː c</v-row
                      >
                    </td>
                  </tr>
                  <tr>
                    <td colspan="2">
                      <i
                        >Analog gilt das <strong>Distributivgesetz </strong>bei
                        der Multiplikation auch, wenn der zweite Faktor
                        „zerlegt“ wird. Die Zerlegung des Divisors funktioniert
                        aber in der Regel nicht.</i
                      >
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-col>

          <v-col cols="gesetzeTabelleFormat">
            <!-- Konstanzgesetz-Tabelle -->
            <v-simple-table>
              <template v-slot:default>
                <tbody>
                  <tr style="text-align: center">
                    <td colspan="2"><strong>Konstanzgesetz</strong></td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Addition: </strong>Das Ergebnis ändert sich nicht,
                      wenn man eine Zahl vergrößert und die andere entsprechend
                      verkleinert.
                    </td>
                    <td class="no-wrap">
                      <v-row no-gutters justify="center">3 + 5 = 4 + 4</v-row>
                      <v-row no-gutters justify="center"
                        >a + b = (a + n) + (b – n)</v-row
                      >
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Subtraktion: </strong>Das Ergebnis ändert sich
                      nicht, wenn man beide Zahlen um dieselbe Zahl vergrößert
                      (oder verkleinert)
                    </td>
                    <td class="no-wrap">
                      <v-row no-gutters justify="center">13 – 8 = 14 – 9</v-row>
                      <v-row no-gutters justify="center"
                        >a – b = (a + n) – (b + n)</v-row
                      >
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Multiplikation: </strong>Das Ergebnis ändert sich
                      nicht, wenn man eine Zahl multipliziert und die andere
                      entsprechend dividiert.
                    </td>
                    <td class="no-wrap">
                      <v-row no-gutters justify="center"
                        >18 · 16 = 36 · 8</v-row
                      >
                      <v-row no-gutters justify="center"
                        >a · b = (a · n) · (b ː n)</v-row
                      >
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Division: </strong>Ergebnis ändert sich nicht,
                      wenn man beide Zahlen durch dieselbe Zahl dividiert (oder
                      entsprechend multipliziert).
                    </td>
                    <td class="no-wrap">
                      <v-row no-gutters justify="center"
                        >48 ː 6 = 24 ː 3
                      </v-row>
                      <v-row no-gutters justify="center"
                        >a ː b = (a ː n) ː (b ː n)</v-row
                      >
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-col>
        </v-row>
      </div>
    </AppButtonCollapse>
    <br />
    <p>
      Um ein genaueres Bild dafür zu bekommen, wie Kinder Aufgaben nutzen und
      welche Rechengesetze und Zusammenhänge dahinterstecken, sollen Sie in den
      folgenden Aktivitäten die Vorgehensweisen von Kim und Tomás nachvollziehen
      und Fragen zu diesen beantworten.
    </p>

    <div style="background-color: white">
      <LernbausteinStempeln
        :id="LBSTStempeln[0].id"
        :workOrder="LBSTStempeln[0].workOrder"
        :elements="LBSTStempeln[0].elements"
        :image="LBSTStempeln[0].image"
      >
        <template v-slot:alternative-content-0>
          <p>
            Kim hat die Aufgabe 8+9 gelöst, in dem sie den ersten Summanden um 1
            verringert hat und den zweiten Summanden um 1 erhöht hat. Das heißt,
            Kim hat sich hier das Konstanzgesetz der Summe zur Hilfe genommen:
            a+b = (a±1)+(b±1).
          </p>
        </template>
        <template v-slot:alternative-content-1>
          <p>
            Sie haben gesehen, wie Kim bei dem Lösen der Aufgabe vorgeht. Sie
            verändert die Summanden der Aufgabe gegensinnig, das ist deshalb
            hilfreich und nützlich, weil dadurch eine leichtere Aufgabe erzeugt
            wird, weil dadurch ein glatter Zehner entsteht und der
            Zehnerübergang somit umgangen wird.
          </p>
        </template>
        <template v-slot:alternative-content>
          <p>
            Kim hat die Aufgabe 8+9 gelöst, in dem sie den ersten Summanden um 1
            verringert hat und den zweiten Summanden um 1 erhöht hat. Das heißt,
            Kim hat sich hier das Konstanzgesetz der Summe zur Hilfe genommen:
            a+b = (a±1)+(b±1).
          </p>
          <p>
            Sie haben gesehen, wie Kim bei dem Lösen der Aufgabe vorgeht. Sie
            verändert die Summanden der Aufgabe gegensinnig, das ist deshalb
            hilfreich und nützlich, weil dadurch eine leichtere Aufgabe erzeugt
            wird, weil dadurch ein glatter Zehner entsteht und der
            Zehnerübergang somit umgangen wird.
          </p>
        </template>
      </LernbausteinStempeln>
    </div>

    <div style="background-color: white">
      <LernbausteinStempeln
        :id="LBSTStempeln[1].id"
        :workOrder="LBSTStempeln[1].workOrder"
        :elements="LBSTStempeln[1].elements"
        :image="LBSTStempeln[1].image"
      >
        <template v-slot:alternative-content-0>
          <p>
            Tomás hat die Aufgabe 8+7 gelöst, in dem er die Sieben so zerlegt
            hat, dass er zunächst zum nächsten Zehner rechnen kann und dann
            weiter. Das heißt, Tomás hat sich hier das Assoziativgesetz zur
            Hilfe genommen: (a+b)+c= a+(b+c).
          </p>
        </template>
        <template v-slot:alternative-content-1>
          <p>
            Sie haben gesehen, wie Tomás bei dem Lösen der Aufgabe vorgeht. Er
            zerlegt den zweiten Summanden, um den Zehnerübergang zu erleichtern,
            weil er dadurch erst zum nächsten Zehner und dann weiter rechnen
            kann.
          </p>
        </template>
        <template v-slot:alternative-content>
          <p>
            Tomás hat die Aufgabe 8+7 gelöst, in dem er die Sieben so zerlegt
            hat, dass er zunächst zum nächsten Zehner rechnen kann und dann
            weiter. Das heißt, Tomás hat sich hier das Assoziativgesetz zur
            Hilfe genommen: (a+b)+c= a+(b+c).
          </p>
          <p>
            Sie haben gesehen, wie Tomás bei dem Lösen der Aufgabe vorgeht. Er
            zerlegt den zweiten Summanden, um den Zehnerübergang zu erleichtern,
            weil er dadurch erst zum nächsten Zehner und dann weiter rechnen
            kann.
          </p>
        </template>
      </LernbausteinStempeln>
    </div>

    <p>
      Auf die Aufgaben des Einspluseins wird auch im erweiterten Zahlenraum
      zurückgegriffen. Gleiches gilt für die Ableitungsstrategien. Somit dient
      die Auseinandersetzung mit der Einspluseinstafel auch der allgemeinen
      Fähigkeit zum beziehungsreichen Rechnen. Durch den Rückgriff auf die
      erarbeiteten Ableitungsstrategien können Kinder sich zudem vom zählenden
      Rechnen lösen (Gaidoschik, 2010; 2014).
    </p>
    <AppLiteraturEPE />
    <AppBottomNavEPE
      next="/einspluseins/hintergrundwissen/darstellungen"
      back="/einspluseins/hintergrundwissen/grundvorstellungen"
    />
  </div>
</template>

<script>
import AppBottomNavEPE from "@/common/AppBottomNavEPE";
import AppLiteraturEPE from "@/common/AppLiteraturEPE";
import AppExpandableImage from "@/common/AppExpandableImage";
import AppButtonCollapse from "@/common/AppButtonCollapse";

import LernbausteinStempeln from "@/components/faledia/explorativeElemente/LernbausteinStempeln";
import LBST_Ableitungsaufgaben_Stempeln_Kim_V1 from "@/components/faledia/seiten/einsPlusEins/LBST/LBST_1+1_Ableitungsaufgaben_Stempeln_Kim_V1.js";
import LBST_Ableitungsaufgaben_Stempeln_Tomas_V1 from "@/components/faledia/seiten/einsPlusEins/LBST/LBST_1+1_Ableitungsaufgaben_Stempeln_Tomas_V1.js";

export default {
  components: {
    AppBottomNavEPE,
    AppLiteraturEPE,
    LernbausteinStempeln,
    AppExpandableImage,
    AppButtonCollapse,
  },
  data: () => ({
    LBSTStempeln: [
      LBST_Ableitungsaufgaben_Stempeln_Kim_V1,
      LBST_Ableitungsaufgaben_Stempeln_Tomas_V1,
    ],
    einspluseinstafelBild: require("@/assets/epe/1+1-Tafel_keine.png"),
  }),
  methods: {
    changeImage: function(image) {
      this.einspluseinstafelBild = image;
    },
  },
};
</script>

<style scoped>
#app .no-wrap {
  white-space: nowrap;
  overflow: auto;
}
.image {
  max-width: 50%;
  margin-inline: auto;
  background-color: white;
}
.flex {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-block-start: 5%;
}
.flexEinspluseins {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-block-start: 7px;
  padding: 10px;
}
.v-data-table > .v-data-table__wrapper > table > tbody > tr > td.eineZeile {
  padding: 2.5%;
}

.v-data-table > .v-data-table__wrapper > table > tbody > tr > td.zweiZeilen {
  padding-block: 0;
  padding-inline: 2.5%;
}
</style>
