function createObject() {
  let example = {
    id: "LBST_1+1_Ableitungsaufgaben_Stempeln_Tomas_V1",
    workOrder: `Links sehen Sie eine Kinderaussage. Rechts finden Sie Fragen dazu. Dabei gibt es unterschiedlich viele Fragen pro Entscheidungsstufe. Sie müssen die jeweils vorangegangene Entscheidungsstufe richtig beantworten, um die Fragen der nächsten Stufe beantworten zu können.`,
    image: require("@/assets/epe/Stempeln/Tomas.png"),
    elements: [
      [
        {
          question:
            "Welches Rechengesetz liegt der Ableitungsstrategie von Tomás zugrunde?",
          answers: [
            {
              correct: true,
              text: "Das Assoziativgesetz",
              response: `Sehr gut. Tomás nutzt hier das Assoziativgesetz. Zur Erinnerung das Assoziativgesetz: (a+b)+c= a+(b+c)`,
            },
            {
              correct: false,
              text: "Das Konstanzgesetz der Differenz",
              response: `Schauen Sie sich das Vorgehen von Tomás noch einmal genauer an. Zur Erinnerung das Konstanzgesetz der Differenz: (a±1)-(b±1), Subtrahend und Minuend werden dabei gleichsinnig verändert.`,
            },
            {
              correct: false,
              text: "Das Konstanzgesetz der Summe",
              response: `Schauen Sie sich das Vorgehen von Tomás noch einmal genauer an. Zur Erinnerung das Konstanzgesetz der Summe: a+b = (a±1)+(b±1), die Summanden werden dabei gegensinnig verändert.`,
            },
            {
              correct: false,
              text: "Das Kommutativgesetz",
              response: `Schauen Sie sich das Vorgehen von Tomás noch einmal genauer an. Zur Erinnerung das Kommutativgesetz: a+b = b+a.`,
            },
          ],
        },
      ],
      [
        {
          question:
            "Welche Eigenschaft der Aufgabe führt dazu, dass es sinnvoll ist, das Gesetz anzuwenden?",
          answers: [
            {
              correct: false,
              text:
                "Das Konstanzgesetz der Summe kann angewendet werden, damit ein Summand ein glatter Zehner wird.",
              response: `Das ist so nicht richtig. Hier werden die Summanden nicht gegensinnig verändert. Vielmehr wird der zweite Summand zerlegt, um den Zehnerübergang zu erleichtern.`,
            },
            {
              correct: false,
              text:
                "Das Konstanzgesetz der Differenz kann angewendet werden, damit der Minuend oder Subtrahend ein glatter Zehner wird.",
              response: `Das ist so nicht richtig. Das Konstanzgesetz der Differenz gilt für Subtraktionsaufgaben. Dabei werden Minuend und Subtrahend gleichsinnig verändert. Hier wird vielmehr der 2. Summand zerlegt, um den Zehnerübergang zu erleichtern.`,
            },
            {
              correct: true,
              text:
                "Durch die Zerlegung eines Summanden kann der Zehnerübergang erleichtert werden.",
              response: `Tomás zerlegt den 2. Summanden, um zunächst zum Zehner und von dort aus weiter zu rechnen. Dadurch wird der Zehnerübergang erleichtert.`,
            },
            {
              correct: false,
              text:
                "Das Kommutativgesetz kann genutzt werden, da die Vertauschung der beiden Summanden zu einer einfacheren Aufgabe führt, beispielsweise weil der erste Summand nach dem Vertauschen besonders nah am Zehnerübergang ist.",
              response: `Das ist hier so nicht richtig. Hier werden die Summanden nicht vertauscht. Grundsätzlich kann diese Strategie aber hilfreich sein.`,
            },
          ],
        },
      ],
    ],
  };
  return example;
}

export default createObject();
